<template>
	<div class="mappanelinfo d-flex" ref="mappanelinfo" style="overflow: hidden">
		<!-- Info Panels -->

		<div class="mappaneldetails overflow-y-auto overflow-x-hidden">
			<transition name="scroll-x-transition" mode="out-in" :duration="100">
				<component :is="panelType" :currentmap="currentmap" :aisStops="aisStops" />
			</transition>
		</div>

		<!-- Tabs -->
		<div class="mt-7 mappaneltabs d-flex flex-column fill-height" style="z-index: 1">
			<!-- Tab Settings -->
			<div
				v-if="!isPanelHide"
				class="tab tabsettings"
				:class="activeTabSettings"
				@click="showTabSettings"
				:title="$t('operations.layers.title')"
			>
				<v-icon v-show="isActiveTabSettings" small color="primary">fas fa-sliders-v</v-icon>
				<v-icon v-show="!isActiveTabSettings" small color="grey">fas fa-sliders-v</v-icon>
			</div>
			<!-- Tab Vessels -->
			<div v-if="!isPanelHide" class="tab tabvessels" :class="activeTabVessel" @click="showTabVessel" :title="$t('operations.vessels.title')">
				<v-icon v-show="isActiveTabVessel" small color="primary">far fa-ship</v-icon>
				<v-icon v-show="!isActiveTabVessel" small color="grey">far fa-ship</v-icon>
			</div>

			<!-- Tab Movements -->
			<div
				v-if="!isPanelHide && this.$store.getters.hasFunctionality('READ_PANEL_MOVEMENT')"
				class="tab tabmovements"
				:class="activeTabMovements"
				@click="showTabMovements"
				:title="$t('operations.movements.title')"
			>
				<v-icon v-show="isActiveTabMovements" small color="primary">far fa-exchange-alt</v-icon>
				<v-icon v-show="!isActiveTabMovements" small color="grey">far fa-exchange-alt</v-icon>
			</div>

			<!-- Dynamic Tabs -->
			<dynamic-tabs :isPanelHide="isPanelHide" :currentmap="currentmap" />

			<!-- Tab hide panel -->
			<div :style="{ left: 0 + 'px' }" class="tabhide" @click="actionHideTab" :title="$t('operations.layers.title')">
				<v-icon v-show="isActiveTabHide" small color="white">far fa-angle-double-right</v-icon>
				<v-icon v-show="!isActiveTabHide" small color="white">far fa-angle-double-left</v-icon>
			</div>
		</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon';
import { SETTINGS_PANEL_ID, VESSEL_PANEL_ID, MOVEMENTS_PANEL_ID } from '@/lib/variables/panels';
import DynamicTabs from '@/components/operations/map/commonpanel/dynamics/DynamicTabs';

const SettingsMapPanel = () => import('@/components/operations/map/sections/infoais/settings/SettingsMapPanel');
const VesselMapPanel = () => import('@/components/operations/map/sections/infoais/vessel/VesselMapPanel');
const MovementsMapPanel = () => import('@/components/operations/map/sections/infoais/movements/MovementsMapPanel');
const DynamicPanels = () => import('@/components/operations/map/commonpanel/dynamics/DynamicPanels');

export default {
	name: 'mappanelinfo',

	mixins: [],

	props: {
		selectedtab: {
			type: String,
			required: false,
			default: SETTINGS_PANEL_ID
		},
		aisStops: {
			type: Array,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		}
	},

	created() {
		this.$store.commit('setUserQuaysId', this.$store.getters.getUserBerths);
	},

	data() {
		return {
			isPanelHide: false
		};
	},

	mounted() {},
	destroyed() {},

	components: {
		SvgIcon,
		SettingsMapPanel,
		VesselMapPanel,
		MovementsMapPanel,
		DynamicPanels,
		DynamicTabs
	},

	computed: {
		panelType() {
			const map = {
				[SETTINGS_PANEL_ID]: 'settings-map-panel',
				[VESSEL_PANEL_ID]: 'vessel-map-panel',
				[MOVEMENTS_PANEL_ID]: 'movements-map-panel'
			};

			return map[this.activePanel] || 'dynamic-panels';
		},

		activePanel() {
			return this.$store.getters.activePanelAIS;
		},

		isActiveTabSettings() {
			return this.$store.getters.activePanelAIS === SETTINGS_PANEL_ID;
		},

		isActiveTabVessel() {
			return this.$store.getters.activePanelAIS === VESSEL_PANEL_ID;
		},

		isActiveTabMovements() {
			return this.$store.getters.activePanelAIS === MOVEMENTS_PANEL_ID;
		},

		activeTabSettings() {
			return {
				tabactive: this.isActiveTabSettings
			};
		},

		activeTabVessel() {
			return {
				tabactive: this.isActiveTabVessel
			};
		},

		activeTabMovements() {
			return {
				tabactive: this.isActiveTabMovements
			};
		},

		isActiveTabHide() {
			return this.isPanelHide;
		}
	},

	methods: {
		actionHideTab() {
			this.isPanelHide = !this.isPanelHide;
			this.$emit('isHidePanel', this.isPanelHide);
		},

		showTabSettings() {
			this.showTab(SETTINGS_PANEL_ID);
		},

		showTabVessel() {
			this.showTab(VESSEL_PANEL_ID);
		},

		showTabMovements() {
			this.showTab(MOVEMENTS_PANEL_ID);
		},

		showTab(panelId) {
			this.$store.commit('setActivePanelAIS', { panelId });
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/app-variables.pcss';
.mappanelinfo {
	width: 100%;
	height: 100%;
}
.mappaneldetails {
	position: relative;
	width: calc(100% - 42px);
	background-color: var(--very-low);
}
.mappaneltabs {
	background-color: transparent;
	width: 42px;
	height: 100vh;
	float: right;

	& .tab {
		width: 40px;
		height: 40px;
		background-color: var(--low);
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom: 1px solid #bbb;
		margin-bottom: 3px;
		font-size: 16px;
		color: var(--primary);
		&.tabsettings {
			i {
				padding-top: 12px;
				padding-left: 12px;
			}
		}
		&.tabvessels {
			i {
				padding-top: 12px;
				padding-left: 12px;
			}
		}
		&.tabactive {
			background-color: var(--very-low);
			i {
				color: var(--eiffel-accent);
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}
.tabhide {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 60px;
	background-color: var(--primary);
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-bottom: 1px solid #bbb;
	margin-bottom: 45px;
	font-size: 16px;
	color: var(--primary);
	float: inline-end;
}
</style>
